(function($) {

$.widget('custom.popup', {

    _create: function() {
        this.init();
        this.registerTargetClickEvent();
        this.registerDocumentClickEvent();
    },
    init: function() {
        var $menu = this.element.find('.popup-menu');
        $menu.menu();
        $menu.hide();
    },
    registerTargetClickEvent: function() {
        var widget = this;
        this.element.find('.popup-button').click(function(event) {
            event.preventDefault();
            widget.togglePopupMenu();
        });
    },
    registerDocumentClickEvent: function() {
        var widget = this;
        $(document).click(function(event) {
            var $target = $(event.target);
            var $matches = $target.closest('.popup');
            if ( ! $matches.length) {
                widget.hidePopupMenu();
            }
        });
    },
    togglePopupMenu: function() {
        var $button = this.element.find('.popup-button');
        $button.toggleClass('active');
        var $menu = this.element.find('.popup-menu');
        $menu.toggle();
    },
    showPopupMenu: function() {
        var $button = this.element.find('.popup-button');
        $button.addClass('active');
        var $menu = this.element.find('.popup-menu');
        $menu.show();
    },
    hidePopupMenu: function() {
        var $button = this.element.find('.popup-button');
        $button.removeClass('active');
        var $menu = this.element.find('.popup-menu');
        $menu.hide();
    }

});

})(jQuery);
