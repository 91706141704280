(function($) {

$.fn.navigation = function(options) {
    return this.each(function() {
        var target = $(this);
        navigation.init(target, options);
    });
}

var navigation = {
    init: function(target, op) {
        var context = this;
        var options = $.extend({}, op);
        options.target = target;
        context.registerForToggleEvent(options);
    },
    registerForToggleEvent: function(options) {
        var context = this;
        $('.toggle-nav').click(function() {
            context.toggle(options);
        });
    },
    toggle: function(options) {
        var context = this;
        if (context.isVisible(options) == false) {
            context.show(options);
        } else {
            context.hide(options);
        }
    },
    isVisible: function(options) {
        return options.target.is('.with-nav');
    },
    show: function(options) {
        options.target.addClass('with-nav');
    },
    hide: function(options) {
        options.target.removeClass('with-nav');
    }
}

})(jQuery);
