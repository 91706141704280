// toggles fields based on the value chosen on another (subject) field
// include data-toggle="subject:value" on the toggled element

(function($){

$(function() {
    $('[data-toggle]').each(function() {
        var $target = $(this);
        var string = $target.data('toggle');
        var components = string.split(':');
        var selector = components[0];
        var test = components[1];
        var $subject = $(selector);
        $subject.change(function() {
            toggle($subject, $target, test);
        });
        toggle($subject, $target, test);
    });
});

function toggle($subject, $target, test) {
    var value = $subject.val();
    if (test == 'something') {
        if (value != '') {
            $target.show();
        } else {
            $target.hide();
        }
    } else {
        if (value == test) {
            $target.show();
        } else {
            $target.hide();
        }
    }
}

})(jQuery);
