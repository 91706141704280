(function($) {

    var navigate = {
        defaults: {
            method: "GET"
        },
        navigate: function(url, options) {
            navigate.options = $.extend({}, navigate.defaults, options)
            if (navigate.options['method'].toUpperCase() == "GET") {
                window.location = url;
            } else {
                navigate.navigateForm(url);
            }
        },
        navigateElement: function(options) {
            var $this = $(this);
            return $this.each(function() {
                navigate.navigate($this.attr('href'), options);
            });
        },
        navigateForm: function(url) {
            var $form = navigate.createForm(url);
            $('body').append($form);
            $form.submit();
        },
        createForm: function(url) {
            return $(
                '<form method="post" action="' + url + '" style="display: none">' +
                    '<input type="hidden" name="_token" value="' + $('meta[name="_token"]').attr('content') + '" />' +
                    '<input type="hidden" name="_method" value="' + navigate.options['method'] + '" />' +
                '</form>'
            );
        }
    }

    $.navigate = navigate.navigate;

    $.fn.navigate = navigate.navigateElement;

})(jQuery);