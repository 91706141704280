(function($) {

$.widget('custom.confirm', {

    options: {
        on: "click",
    },
    _setOptions: function(options) {
        this._super(options);
        this._refresh();
    },
    _setOption: function(key, value) {
        this._super(key, value);
    },

    // Creates and destroys the widget.

    _create: function() {
        this.confirmed = false;
        var $dialog = this.createContainer();
        this.dialog = $dialog;
        var text = this.element.data('confirm');
        $dialog.find('.confirm-content p').text(text);
        $dialog.hide();
        this.element.after($dialog);
        this.registerClickEvent();
        this.element.trigger('change');
    },
    _destroy: function() {
        this.dialog.remove();
        this.element.show();
    },
    _refresh: function() {
    },

    // Creates the HTML.

    createContainer: function() {
        return $(
            '<div class="dialog confirm-dialog">' +
                '<div class="confirm-content"><p></p></div>' +
            '</div>'
        );
    },
    registerClickEvent: function() {
        var widget = this;
        this.element.on(this.options.on, function(event) {
            return widget.showDialog(event);
        });
    },
    showDialog: function(event) {
        if (this.confirmed) {
            this.confirmed = false;
            return true;
        }
        var widget = this;
        var height = window.innerHeight;
        var dialogHeight = height - 80;
        this.dialog.dialog({
            buttons: [
                {
                    text: 'OK',
                    click: function() {
                        widget.confirmed = true;
                        $(this).dialog('close');
                        widget.element.trigger(widget.options.on);
                    }
                },
                {
                    text: 'Cancel',
                    click: function() {
                        $(this).dialog('close');
                    }
                }
            ],
            maxHeight: dialogHeight,
            modal: true
        });
        event.stopImmediatePropagation();
        return false;
    },

});

})(jQuery);