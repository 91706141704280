(function($) {

$(function() {
    registerForTypeChangeEvent();
    updatePlaceholdersForContacts();

    $(document).bind('ajax.insert', function() {
        registerForTypeChangeEvent();
        updatePlaceholdersForContacts();
    });
});

function registerForTypeChangeEvent() {
    $('.contacts-type-input').change(function() {
        var $type = $(this);
        var $contact = $type.closest('#contacts .contact');
        updatePlaceholderForContact($contact);
    });
}

function updatePlaceholdersForContacts() {
    $('#contacts .contact').each(function() {
        var $contact = $(this);
        updatePlaceholderForContact($contact);
    });
}

function updatePlaceholderForContact($contact) {
    var $type = $contact.find('.contacts-type-input');
    var selection = $type.val();
    var $option = findOptionWithValue($type, selection);
    var $value = $contact.find('.contacts-value-input');
    $value.attr('placeholder', $option.text());
}

function findOptionWithValue($select, value) {
    var match = null;
    $select.find('option').each(function() {
        var $option = $(this);
        if ($option.val() == value) {
            match = $option;
        }
    });
    return match;
}

})(jQuery);
