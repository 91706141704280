(function($) {

$(function() {
    $('.hide-instructions').click(function(event) {
        event.preventDefault();
        var $sender = $(this);
        var $instructions = $sender.closest('.instructions');
        $instructions.hide();
        $.ajax('/account/option', {
            data: {
                hide_instructions: true
            },
            success: function(data, status) {
                console.log(data);
            }
        });
    });
});

})(jQuery);
