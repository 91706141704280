(function($) {

$(function() {
    $('.ajax').ajax();
    $('.dragdrop').dragdrop();
    $('.navigation').navigation();
    $('.tabs').tabs();

    $(document).bind('ajax.insert', function() {
        init();
    });

    init();
});

function init() {
    $(document).tooltip({
        'position': {
            'my': 'center top',
            'at': 'center bottom+10px',
            'collision': 'flipfit'
        }
    });

    $('.popup').popup();

    $('select').chosen({
        'search_contains': true,
        'width': '100%'
    });

    $('.type-date').datetime({ 
        'mode': 'date'
    });

    $('.type-datetime').datetime({
        'mode': 'datetime'
    });

    $('a[data-confirm]').confirm({
        'on': 'click'
    });

    $('form[data-confirm]').confirm({
        'on': 'submit'
    });

    $('a[data-method]').click(function(event) {
        event.preventDefault();
        var $this = $(this);
        $this.navigate({
            method: $this.data('method')
        });
    });
}

})(jQuery);
