(function($) {

$.widget('custom.chooser', {
    
    options: {},
    
    _create: function() {
        this.initialize();
        this.options.dialog.hide();
    },
    
    initialize: function() {
        this.registerInputGroupClickEvent();
        this.registerInputGroupDialogSelectButtonClickEvent();
        this.registerInputGroupDialogCheckboxClickEvent();
        this.registerInputGroupDialogInputChangeEvent();
        this.updateInputGroup();
    },
    
    registerInputGroupClickEvent: function() {
        var widget = this;
        widget.element.click(function(event) {
            event.preventDefault();
            widget.createInputGroupDialog();
        });
    },
    
    createInputGroupDialog: function() {
        var widget = this;
        var windowHeight = window.innerHeight;
        var height = windowHeight - 80;
        widget.options.dialog.dialog({
            buttons: [
                {
                    text: 'Done',
                    click: function() {
                        widget.options.dialog.dialog('close');
                    }
                }
            ],
            height: height,
            modal: true
        });
        widget.updateInputGroupDialogCSS();
    },
    
    updateInputGroupDialogCSS: function() {
        var $instance = this.options.dialog.dialog('instance');
        var $container = $instance.uiDialog;
        $container.css('left', '');
        $container.css('top', '');
        $container.css('width', '');
    },
    
    registerInputGroupDialogSelectButtonClickEvent: function() {
        var widget = this;
        widget.options.dialog.find('.select-button input').click(function() {
            var $checkbox = $(this);
            var checked = $checkbox.prop('checked');
            widget.options.dialog.find('.chooser-item').each(function() {
                var $item = $(this);
                var $input = $item.find('input');
                if ( ! $input.is(':disabled')) {
                    $input.prop('checked', checked);
                }
            });
            widget.updateInputGroup();
        });
    },
    
    registerInputGroupDialogCheckboxClickEvent: function() {
        var widget = this;
        widget.options.dialog.find('.chooser-item input[type="checkbox"]').click(function() {
            widget.updateInputGroup();
        });
    },
    
    registerInputGroupDialogInputChangeEvent: function() {
        var widget = this;
        widget.options.dialog.find('.chooser-item input').change(function() {
            widget.updateInputGroup();
        });
    },
    
    updateInputGroup: function() {
        var input = this.options.updateInputGroupInput(this);
        this.element.find('.input-group-input input').val(input);
        var text = this.options.updateInputGroupText(this);
        if (text != '') {
            this.element.find('.input-group-text').text(text);
        } else {
            var placeholder = this.element.attr('placeholder');
            this.element.find('.input-group-text').text(placeholder);
        }
    },
    
    updateInputGroupInput: function(widget) {
        // defined when registering the widget....
    },
    
    updateInputGroupText: function(widget) {
        // defined when registering the widget...
    }
    
});

})(jQuery);
