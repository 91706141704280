(function($) {

$.fn.ajax = function(options) {
    return this.each(function() {
        var target = $(this);
        ajax.init(target, options);
    });
}

var ajax = {
    init: function(target, op) {
        var context = this;
        var defaults = {};
        var options = $.extend({}, defaults, op);
        options.target = target;
        context.updateIndexWithItemCount(options);
        context.registerForInsertEvent(options);
        context.registerForRemoveEvent(options);
    },
    updateIndexWithItemCount: function(options) {
        var context = this;
        options.index = options.target.find('.ajax-item').length;
    },
    registerForInsertEvent: function(options) {
        var context = this;
        options.target.find('.ajax-insert').click(function(event) {
            event.preventDefault();
            var $destination = options.target.find('.ajax-destination');
            context.insert($destination, options);
        });
    },
    insert: function($destination, options) {
        var context = this;
        var view = options.target.data('view');
        var attribute = options.target.data('attribute');
        var index = options.index;
        $.ajax(view, {
            data: {
                'attribute': attribute + '[' + index + ']'
            },
            success: function(data, status, request) {
                var $item = $(data)
                $destination.append($item);
                context.registerForRemoveEventForItem($item, options);
                $(document).trigger('ajax.insert');
            }
        });
        options.index = index + 1;
    },
    registerForRemoveEvent: function(options) {
        var context = this;
        var $items = options.target.find('.ajax-item');
        $items.each(function() {
            var $item = $(this);
            context.registerForRemoveEventForItem($item, options);
        });
    },
    registerForRemoveEventForItem: function($item, options) {
        var context = this;
        $item.find('.ajax-remove').click(function(event) {
            event.preventDefault();
            $item.remove();
        });
    }
};

})(jQuery);
