(function($) {

$(function() {
    $('.autosubmit').change(function() {
        var $trigger = $(this);
        var $form = $trigger.closest('form');
        $form.submit();
    });
});

})(jQuery);
