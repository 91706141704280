(function($) {

$.fn.dragdrop = function(options) {
    return this.each(function() {
        var target = $(this);
        dragdrop.init(target, options);
    });
}

var dragdrop = {
    init: function(target, op) {
        var context = this;
        var defaults = {};
        var options = $.extend({}, defaults, op);
        options.target = target;
        context.updateIndexWithItemCount(options);
        
        // Register for events.
        context.registerForClickEvent(options);
        context.registerForDragEvent(options);
        context.registerForDropEvent(options);
        context.registerForRemoveEvent(options);
        context.registerForOtherInputChangeEvent(options);
    },
    updateIndexWithItemCount: function(options) {
        var context = this;
        options.index = options.target.find('.dragdrop-item').length;
    },
    registerForClickEvent: function(options) {
        var context = this;
        options.target.find('.dragdrop-draggable').click(function(event) {
            event.preventDefault();
            var $destination = options.target.find('.dragdrop-destination');
            var $token = $(this);
            context.insert($destination, $token, options);
        });
    },
    registerForDragEvent: function(options) {
        var context = this;
        options.target.find('.dragdrop-draggable').draggable({
            'helper': 'clone',
            'revert': 'invalid'
        });
    },
    registerForDropEvent: function(options) {
        var context = this;
        options.target.find('.dragdrop-destination').droppable({
            drop: function(event, ui) {
                var $destination = $(this);
                var $token = ui.draggable;
                context.insert($destination, $token, options);
            }
        });
    },
    insert: function($destination, $token, options) {
        var context = this;
        if (context.unique($destination, $token.text())) {
            var view = options.target.data('view');
            var attribute = options.target.data('attribute');
            var index = options.index;
            var id = $token.data('id');
            var name = $token.data('name');
            var data = { 
                attribute: attribute + '[' + index + ']',
                id: id,
                name: name
            };
            $.ajax(view, {
                data: data,
                success: function(data, status, request) {
                    var $item = $(data)
                    $destination.append($item);
                    context.registerForRemoveEventForItem($item, options);
                    $(document).trigger('dragdrop.insert');
                }
            });
            options.index = index + 1;
        }
    },
    unique: function($destination, needle, options) {
        var context = this;
        var unique = true;
        $destination.find('.dragdrop-item').each(function() {
            var $item = $(this);
            var $text = $item.find('.dragdrop-text');
            var text = $text.text();
            if (text == needle) {
                unique = false;
            }
        });
        return unique;
    },
    registerForRemoveEvent: function(options) {
        var context = this;
        var $items = options.target.find('.dragdrop-item');
        $items.each(function() {
            var $item = $(this);
            context.registerForRemoveEventForItem($item, options);
        });
    },
    registerForRemoveEventForItem: function($item, options) {
        var context = this;
        $item.find('.dragdrop-remove').click(function(event) {
            event.preventDefault();
            $item.remove();
        });
    },
    registerForOtherInputChangeEvent: function(options) {
        var context = this;
        var $destination = options.target.find('.dragdrop-destination');
        var $container = options.target.find('.token-other');
        var $input = $container.find('input');
        var $token = $container.find('.token');
        $input.keypress(function(event) {
            var $this = $(this);
            if (event.which == 13) {
                event.preventDefault();
                context.insert($destination, $token, options);
                context.updateOtherInput($this, '');
            }
        });
        $input.keyup(function(event) {
            var $this = $(this);
            context.updateTokenForOtherInput($this);
        });
        context.updateTokenForOtherInput($input);
    },
    updateOtherInput: function($input, value) {
        var context = this;
        $input.val(value);
        context.updateTokenForOtherInput($input);
    },
    updateTokenForOtherInput: function($input, options) {
        var context = this;
        var $container = $input.closest('.token-other');
        var $token = $container.find('.token');
        var value = $input.val();
        context.updateOtherToken($token, value);
    },
    updateOtherToken: function($token, value, options) {
        var context = this;
        $token.data('name', value);
        $token.text(value);
        var visible = value ? true : false;
        context.updateVisibleForOtherToken($token, visible);
    },
    updateVisibleForOtherToken: function($token, visible, options) {
        if (visible) {
            $token.show();
        } else {
            $token.hide();
        }
    }
};

})(jQuery);
